<div class="modal fade show d-block modal-sm">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content modal-container">

        <div class="modal-body">
          <!-- <div class="container"> -->
            <div class="row">
              <div class="col-12">
                <div class="landing-text">Please enter your zip code to narrow down to an office near you.</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>
            <div class="row">

              <div class="col-md-4">
                <label for="zipCode" class="label-text">Zip Code</label>
                <input id="zipCode" type="text" class="form-control form-style" placeholder="" aria-label="Zip Code" [(ngModel)]="zip">
              </div>
              <div class="col-md-4">
                <label for="distanceSel" class="label-text">Distance</label>
                <select class="form-select form-style" id="distanceSel" [(ngModel)]="distance">
                  <option value="0">-- Choose One --</option>
                  <option value="5">5 Miles</option>
                  <option value="20">20 Miles</option>
                  <option value="50">50 Miles</option>
                </select>

              </div>
              <div class="col-md-2 mt-4 pe-3">
                <button type="button" class="btn locate-btn" [disabled]="!distance || !zip" (click)="getOffices()">Locate</button>
              </div>
              <div class="col-md-2 mt-4">
                <button type="button" class="btn clear_btn" (click)="clear()">Clear</button>
              </div>

            </div>
            <div class="row mt-5" *ngIf="!showPlans && !showNoEntries">
              <div class="col-12 empty-results mb-3">
                <img src="../../../assets/images/searchIcon.png" alt="">
              </div>
              <div class="col-12 empty-search-text">
                Search for location to see the results
              </div>
            </div>
            <div class="row mt-5" *ngIf="showNoEntries">
              <div class="col-12 empty-results">
                <i class="fa fa-search" style="width: 56px;height: 56px;"></i>
              </div>
              <div class="col-12 empty-search-text">
                No Offices found, please change your search criteria and try again.
              </div>
            </div>
            <div class="row mt-5" *ngIf="showPlans">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-12">
                    <div class="card mb-2" *ngFor="let office of offices">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_318_5294)">
                                <path d="M18.36 9L18.96 12H5.04L5.64 9H18.36ZM20 4H4V6H20V4ZM20 7H4L3 12V14H4V20H14V14H18V20H20V14H21V12L20 7ZM6 18V14H12V18H6Z" fill="#009DFF"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_318_5294">
                                  <rect width="24" height="24" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div class="col-8">
                            <div class="office-name">{{office.title}}</div>
                            <div class="address">
                              {{office.address.line1}}<br>
                              {{office.address.city}}, {{office.address.state}} {{office.address.zipCode}}
                            </div>
                            <div class="phone-number">
                              {{ office.phone | phone}}
                            </div>
                          </div>
                          <div class="col-3">
                            <button class="btn select-btn" (click)="close(office.id)" routerLink="/office/{{office.id}}">SELECT</button>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                <!--    <div class="row">-->
                <!--      <div class="col-12 text-center">-->
                <!--        <button class="btn btn-primary" (click)="close()">Close</button>-->
                <!--      </div>-->
                <!--    </div>-->

              <!-- </div> -->

            </div>
          </div>
        </div>

</div>



