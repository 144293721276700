<div class="container">
  <div class="row mt-5">
    <div class="col-12">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_245_1774)">
          <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="#009DFF"/>
        </g>
        <defs>
          <clipPath id="clip0_245_1774">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <label class="back" routerLink="/office/{{details.practiceId}}">Back</label>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12">
      <label class="main-header-text">Exclusive Dental Plan Savings - </label>
      <label class="plan-name-header-text mx-2"> {{em.selectedLevel.planName}}</label>
    </div>
  </div>
  <div class="row mt-5" *ngIf="details.salesPlanMarketingDetails?.planSummary">
    <div class="col-12">
      <app-card-container>
        <div class="row">
          <div class="col-12">
            <div class="header-text mb-4">Plan Summary</div>
          </div>
        </div>
        <div class="row" *ngFor="let detail of details.salesPlanMarketingDetails?.planSummary; let i = index ">
          <div class="col-12"><label class="summary-bold-text">{{detail.itemName}}:</label> {{detail.value[0].column1}}</div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <label class="tax-text">* Applicable Taxes May Apply</label>
          </div>
          <div class="col-lg-12 col-12">
            <label class="fee-text">* Price includes a one-time, nonrefundable processing fee of $15.00</label>
          </div>
        </div>
      </app-card-container>
    </div>
  </div>
  <div class="row mt-5" *ngIf="details.salesPlanMarketingDetails?.planBenefits">
    <div class="col-12">
      <app-card-container>
        <div class="row">
          <div class="col-12">
            <div class="header-text">Plan Benefits</div>
          </div>
        </div>
        <div class="row mt-5 mb-3">
          <div class="col-4 table-title">Description</div>
          <div class="col-4 table-title">Qty</div>
          <div class="col-4 table-title">Value</div>
          <div class="col-12 border-gray mt-2"></div>
        </div>
        <div class="row mb-3" *ngFor="let item of details.salesPlanMarketingDetails?.planBenefits ; let ii = index ">
          <div class="row">
            <div class="col-12 section-header mt-3 mb-2">{{item.itemName}}</div>
          </div>
          <div class="row mt-2" *ngFor="let items of item.value ; let a = index">
            <div class="col-4 mb-3">{{items.column1}}</div>
            <div class="col-4 mb-3">{{items.column2}}</div>
            <div class="col-4 mb-3">{{items.column3}}</div>
            <div class="col-12 border-gray"></div>
          </div>
        </div>
      </app-card-container>
    </div>
  </div>
  <div class="row mt-5" *ngIf="details.salesPlanMarketingDetails?.commonDentalPlanSavings">
    <div class="col-12">
      <app-card-container>
        <div class="row">
          <div class="col-12">
            <div class="header-text">Common Dental Plan Savings</div>
          </div>
        </div>
        <div class="row mt-5 mb-3">
          <div class="col-4 table-title">ADA Code</div>
          <div class="col-4 table-title">Procedure Description</div>
          <div class="col-4 table-title">Member Pays*</div>
          <div class="col-12 border-gray mt-2"></div>
        </div>
        <div class="row mb-3" *ngFor="let item of details.salesPlanMarketingDetails?.commonDentalPlanSavings ; let ii = index ">
          <div *ngIf="item.itemName != 'Footer'">
            <div class="row">
              <div class="col-12 section-header mt-3 mb-2">{{item.itemName}}</div>
            </div>
            <div class="row mt-2" *ngFor="let items of item.value ; let a = index">
              <div class="col-4 mb-3">{{items.column1}}</div>
              <div class="col-4 mb-3">{{items.column2}}</div>
              <div class="col-4 mb-3">{{items.column3}}</div>
              <div class="col-12 border-gray"></div>
            </div>
          </div>
        </div>
        <div class="row mt-5 mb-3">
          <div class="col-12 col-lg-12 mb-3 sub-text2" *ngIf="footer != 'X%'">
            *Member savings may vary by location. Free exams and x-rays have no limitations. Any services not listed are available at a minimum of {{footer}} off the office’s Retail Fee(s).
          </div>
          <div class="col-12 col-lg-12 mb-3 sub-text">
            You are responsible for payment of the dentist’s normal fees for any of the following limited services:
          </div>
          <div class="col-12 col-lg-12 mb-3 sub-text2">
            Replacement of partial dentures is limited to one per arch every two years. <br>
            Replacement of full upper and lower dentures are limited to one per arch every two years. <br>
            Replacement of fixed prosthetics such as crowns, bridges, inlays, and onlays is limited to once every year.
          </div>
        </div>
      </app-card-container>
    </div>
  </div>
  <div class="row mt-5 mb-4" *ngIf="em.legalLanguage.adminBy == 'caregington'">
    <div class="col-12 col-lg-12">
      <app-card-container>
        <div class="row mb-3">
          <div class="col-12">
            <div class="header-text">Additional Member Savings</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            Just $30 per person to add additional family members onto your plan
          </div>
        </div>
      </app-card-container>
    </div>
  </div>
  <div class="row mt-5 mb-4">
    <div class="col-12 col-lg-12">
      <app-card-container>
        <div class="row mb-3">
          <div class="col-12">
            <div class="header-text">Exclusions</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 sub-text">
            <p>The following services are not included in the plan and you are responsible for payment of the dentist’s normal fees for these services:</p>
            <br>
            <p>Services provided by a dentist who is not participating in the plan.</p>
            <br>
            <p>Procedures deemed not reasonably necessary or not customarily performed, including, but not limited to services that have a poor prognosis and duplicate prosthetic devices or appliances, as determined by the dentist.</p>
            <br>
            <p>Anesthesiologist services.</p>
            <br>
            <p>Treatment of jaw fractures or dislocations, congenital or developmental malformations, malignancies, cysts or neoplasms, or Temporomandibular Joint Syndrome (TMJ).</p>
            <br>
            <p>Courses of treatment which began prior to the effective date of your plan and/or expenses incurred after termination of your plan.</p>
            <br>
            <p>Any dental disease, defect or injury that arises out of any occupational incident or exposure for which you can receive benefits under applicable workers’ compensation law.</p>
            <br>
            <p>Services of a specialist, including specialty X-rays such as a CBCT's.</p>
            <br>
            <p>Cleaning types are determined by the diagnosis of the General Practitioner. Prophylaxis benefits are excluded in the presence of periodontal disease</p>
          </div>
        </div>
      </app-card-container>
    </div>
  </div>
</div>
<app-legal-text [officeData]="officeData"></app-legal-text>
